var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.members,
      loading: _vm.dataLoading
    },
    scopedSlots: _vm._u([
      {
        key: "handlers",
        fn: function(text, record) {
          return _c(
            "div",
            {},
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { block: "", size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.manageUser(record.user_id)
                    }
                  }
                },
                [_vm._v("\n      Manage\n    ")]
              )
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }